.landing-grid {
  display: grid;
  grid-template-columns: 2fr 1fr;
  grid-gap: 4rem;
}

.landing-banner {
  position: relative;
  overflow: hidden;
  background-color: $secondary;

  .navbar {
    position: relative;
    z-index: 1;
  }

  .content {
    display: flex;
    justify-content: center;
    flex-direction: column;
    color: $white;
    height: 100%;
    width: fit-content;
    margin: 0 auto;
    margin-bottom: 5vw;
    text-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
    h1 {
      z-index: 1;
      font-size: 6vw;
      line-height: 6vw;
      font-weight: 700;
    }
    h2 {
      z-index: 1;
      font-size: 3vw;
      line-height: 3vw;
      font-weight: 700;
    }
  }
  img {
    position: absolute;
    top: 0;
    z-index: 0;
    width: 100%;
    height: auto;
    mix-blend-mode: multiply;
  }
}

.landing-img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}


@media only screen and (max-width: 900px) {
  .landing-grid {
    grid-template-columns: 1fr;
    grid-gap: 1rem;
  }
}
