
.call-to-action{
  display: flex;
  flex-direction: column;
  grid-gap: 1rem;
  justify-content: center;
  align-items: center;
  scale: 0.75;

  h1, h4, h5 { text-align: center; }
  p {
    text-align: left;
  }
}