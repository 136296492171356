
.quote-form {
  display: grid;
  gap: 0.5rem;
  width: 100%;
  margin: 0 auto;
  max-width: 500px;
  .input {
    padding: 0.5rem;
    width: 100%;
    font-size: 1rem;
    border: 1px solid #ccc;
  }
}
.quote-status {
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  width: 100%;
  font-size: 1.5rem;
  color: #333;
  margin: 1rem 0;
  height: 2rem;
}
