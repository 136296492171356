$white: #F0F1F1;
$black: #1C2321;
$gray: mix($white, $black, 50%);

$primary: #660000;
$secondary: #D9B359;
$tertiary: #2E3E14;


$backgroundColor: $white;
$bodyColor: $black;

$border-radius: 0.25rem;
