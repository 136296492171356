@use "sass:math";

@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,300;0,400;0,600;0,700;0,900;1,100;1,300;1,400;1,600;1,700;1,900&display=swap');

// Base font size and sans-serif font
$base-font-size: 16px;
$font-header: 'Montserrat', sans-serif;
$font-body: $font-header;

// Define scale for heading sizes
$scale-ratio: 1.2; // Reduced for simplicity

// Define breakpoints for responsiveness
$breakpoint-small: 480px;
$breakpoint-medium: 768px;
$breakpoint-large: 1024px;

@mixin responsive-font-size($size) {
  font-size: $size;

  @media (min-width: $breakpoint-small) {
    font-size: math.div($size * 1.1, 1);
  }

  @media (min-width: $breakpoint-medium) {
    font-size: math.div($size * 1.2, 1);
  }

  @media (min-width: $breakpoint-large) {
    font-size: math.div($size * 1.3, 1);
  }
}

body {
  font-family: $font-body;
  font-size: $base-font-size;
  line-height: 1.5; // Simplified line-height
}

h1, h2, h3, h4, h5, h6 {
  font-family: $font-header;
  font-weight: bold;
  margin: 0;
}

.page-title {
  @include responsive-font-size(math.div($base-font-size * pow($scale-ratio, 6), 1));
  line-height: normal;
  margin-bottom: 1rem;
}

h1 {
  @include responsive-font-size(math.div($base-font-size * pow($scale-ratio, 3), 1));
}

h2 {
  @include responsive-font-size(math.div($base-font-size * pow($scale-ratio, 2), 1));
}

h3 {
  @include responsive-font-size(math.div($base-font-size * pow($scale-ratio, 2), 1));
}

h4 {
  @include responsive-font-size(math.div($base-font-size * pow($scale-ratio, 1), 1));
}

h5 {
  @include responsive-font-size($base-font-size);
}

h6 {
  @include responsive-font-size(math.div($base-font-size, pow($scale-ratio, 1)));
}



p, a {
  font-family: $font-body;
  @include responsive-font-size($base-font-size);
  a {
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }
}
