
.button {
  font-family: $font-header;
  background-color: $secondary;
  padding: 1rem;
  color: $black;
  border-radius: $border-radius;
  border: 1px solid $tertiary;
  font-weight: 700;
  text-decoration: none;
  width: fit-content;
  margin: 0 auto;
  box-shadow: 0 5px 0 $tertiary;
  transition: background-color 0.5s ease, box-shadow 0.5s ease, margin-top 0.5s ease;
  &:hover {
    background-color: $primary;
    color: $white;
    text-decoration: none;
  }
  &:active {
    background-color: $primary;
    color: $white;
    text-decoration: none;
    transition: background-color 0.5s ease;
    box-shadow: 0 0 0 $tertiary;
    margin-top: 5px;
  }
}