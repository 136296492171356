@import "variables";
@import "typography";

.footer {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: flex-start;
    padding: 3.5rem 1rem;
    list-style: none;
    background-color: $gray;
    color: $white;
    margin-top: auto;
}

.footer-logo {
    font-size: 1.5rem;
    font-weight: 700;
    text-decoration: none;
    font-family: $font-header;
    color: $white;
}

.footer-links {
    display: flex;
    flex-direction: column;

    align-items: center;
    width: fit-content;
    a {
      width: fit-content;
      padding: 0.25rem 0.5rem;
      font-weight: 700;
      color: $white;
      text-decoration: none;
      &:hover {
        text-decoration: underline;
      }
    }
}

@media only screen and (max-width: 700px) {
  .footer {
    flex-direction: column;
  }
}
