@import "variables";
@import "typography";

.navbar-container {
    position: relative;
    background-color: $primary;
    color: white;
}

.navbar {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0 1rem;
    list-style: none;
    z-index: 10;
}

.nav-logo {
    font-size: 1.5rem;
    font-weight: 700;
    text-decoration: none;
    font-family: $font-header;
    color: $white;
}

.nav-links {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: fit-content;
    a {
      width: fit-content;

      padding: 0.25rem 0.5rem;

      color: $white;
      text-decoration: none;
      &:hover {
        background-color: $secondary;
        color: $black;
        font-weight: bold;
      }
    }
    .active {
      padding: 0.25rem;
      background-color: $primary;
    }

}