@use "sass:math";

.post-splash {
  width: 100%;
  aspect-ratio: 3/1;
  height: auto;
  background-size: cover;
  text-align: center;
}

.post-grid {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 1rem;
  margin-bottom: 3rem;
  ul, ol {
    margin: 1rem 2rem;
  }
}

.post-title {
  display: flex;
  align-items: center;
  justify-content: center;
  text-wrap: balance;
  margin: 3rem 0;
  h1 {
    @include responsive-font-size(math.div($base-font-size * pow($scale-ratio, 4), 1));
    max-width: 66%;
    border-bottom: $primary 1rem solid;
    padding: 1rem 0;
    font-style: italic;

  }
}


@media screen and (max-width: 768px) {
  .post-title h1 { max-width: 100%; }
}