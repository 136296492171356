

.posts-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  grid-gap: 1rem;
  margin-bottom: 3rem;
  .post-card {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-color: $white;
    color: $black;
    border-radius: $border-radius;
    border: 1px solid #CCC;
    transition: box-shadow 0.5s ease, border 0.5s ease, background-color 0.5s ease;
    text-decoration: none;

    &:hover {
      background-color: $secondary;
      box-shadow: 10px 10px 0px $tertiary;
      border: 1px solid $tertiary;
    }
    .splash {
      width: 100%;
      border-radius: $border-radius $border-radius 0 0;
    }
    .post-card-content {
      padding: 1rem;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      padding: 1rem;
      transition: padding 0.5s ease;
      h1 {
        font-size: smaller;
        text-wrap: balance;
        margin-bottom: 1rem;
      }
      p {
        font-size: smaller;
        margin-bottom: 1rem;
      }
    }
    .post-card-footer {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 1rem;
      a {
        background-color: $secondary;
        padding: 1rem;
        color: $black;
        border-radius: $border-radius;
        font-weight: 700;
        text-decoration: none;
      }
      a:hover {
        background-color: $primary;
        color: $white;
        text-decoration: none;
        transition: background-color 0.5s ease;
      }
    }
  }
}

.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  a {
    padding: 0.5rem;
    margin: 0 0.25rem;
    text-decoration: none;
    color: $black;
    border-radius: $border-radius;
    &:hover {
      background-color: $primary;
      color: $white;
      text-decoration: none;
      transition: background-color 0.5s ease;
    }
  }
}
