@import "variables";
@import "typography";
@import "button";
@import "navbar";
@import "footer";
@import "images";
@import "landing";
@import "quote";
@import "posts";
@import "post";
@import "call-to-action";


* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}


body {
  background: $backgroundColor;
  color: $bodyColor;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}


.container {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 1rem;
}

.primary-bg {
  background-color: $primary;
  color: $white
}

.secondary-bg {
  background-color: $secondary;
  padding: 2rem 0;
}

.tertiary-bg {
  background-color: $tertiary;
  color: $white;
}

.page-grid {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 2rem;
}

dl {
  margin: 1rem 0;
}
dt {
  font-weight: 700;
}